import React, { Suspense, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';

import './App.less';
import 'antd/dist/antd';
import { globalActions } from './store/global';
import store, { useAction } from './store';
import config from './config/router';
import Container from './components/container';
import { renderRoutes } from './components/router/renderRoutes';
interface ILogin {
  role: Array<number>;
}

function App() {
  const history = useHistory();
  const { login } = useAction(globalActions);

  // 检测当前用户有没有权限进入当前页面
  const checkLogin = (payload: ILogin) => {
    const currentRole = 1;
    if (payload.role.includes(currentRole)) {
      login({ role: currentRole });
    } else {
      login({ role: currentRole });
      history.push('/no-access');
    }
  };

  const _config = config.map((item) => {
    if (item.login) {
      item.hook = () => checkLogin({ role: item.role });
    }

    return item;
  });

  useEffect(() => {
    if (typeof window.IntersectionObserver === 'undefined') {
      import('intersection-observer').then(() =>
        console.log('hack intersection-observer'),
      );
    }
  }, []);

  return (
    <Provider store={store}>
      <Container>
        {/* 这里可以自定义异步请求组件时的loading效果 */}
        <Suspense fallback={<span />}>{renderRoutes(_config)}</Suspense>
      </Container>
    </Provider>
  );
}

export default App;
