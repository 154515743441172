import { backObject } from '../../utils';
import { globalInitState } from './state';
export const updateType = Symbol('STORE_UPDATE');

export default (state = globalInitState, action) => {
  switch (action.type) {
    case updateType:
      const payload = backObject(action.payload);
      return { ...state, ...payload };
    default:
      return state;
  }
};

export { actions as globalActions } from './action';
export type { GlobalActionsIProps } from './action';
export type { GlobalStateI } from './state';
