import { IRouterConfig } from 'config/router';
import React, { lazy, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const documentTitleHandle = (title: string | (() => void)): void => {
  if (typeof title === 'function') {
    title();
  } else if (typeof title === 'string') {
    try {
      const doc = window.top ? window.top.document : document;
      doc.title = title as string;
    } catch (e) {}
  }
};

interface IProps {
  title: IRouterConfig['title'];
  hook: IRouterConfig['hook'];
  unMount: IRouterConfig['unMount'];
  component: IRouterConfig['component'];
  extraProps: { [key: string]: unknown };
}

export default function RouterComponent({
  title,
  component,
  hook,
  extraProps,
}: IProps) {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (hook) {
      hook({ location, history });
    }
  }, [hook]);

  title && documentTitleHandle(title);

  if (component) {
    const Container = lazy(component);
    return <Container {...extraProps} />;
  }
  return null;
}
