import { IRouterConfig } from 'config/router';
import React from 'react';
import { Switch, Route } from 'react-router';
import RouteItem from './';

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) => {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route: IRouterConfig, i) => (
        <Route
          key={`${route.path}-${i}`}
          path={route.path || '/'}
          exact={route.exact}
          strict={route.strict}
          render={() => {
            return (
              <RouteItem
                title={route.title}
                hook={route.hook}
                unMount={route.unMount}
                key={route.path}
                component={route.component}
                extraProps={{ ...extraProps, routes: route.routes }}
              />
            );
          }}
        />
      ))}
    </Switch>
  ) : null;
};
