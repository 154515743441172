import axios, { AxiosRequestConfig } from 'axios';
import { showLoading, hideLoading } from './loading';

// 正在请求
const pendingMap = new Map();

/**
 * 生成每个请求唯一健
 *
 * @param {@} config
 * @returns string
 * */
function getPendingKey(config: AxiosRequestConfig) {
  const { url, method, params } = config;
  let data = config.data;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
function addPending(config: AxiosRequestConfig) {
  const pendingKey = getPendingKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config: AxiosRequestConfig) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}

function myAxios(axiosConfig: AxiosRequestConfig) {
  const service = axios.create({
    timeout: 10000, // 设置统一的超时时长
    withCredentials: true,
  });

  service.interceptors.request.use(
    (config) => {
      if (config.headers.isLoading !== false) {
        showLoading();
      }
      removePending(config);
      addPending(config);
      return config;
    },
    (error) => {
      if (error.config?.headers?.isLoading !== false) {
        hideLoading();
      }
      return Promise.reject(error);
    },
  );

  service.interceptors.response.use(
    (response) => {
      // 判断当前请求是否设置了不显示Loading
      if (response.config?.headers?.isLoading !== false) {
        hideLoading();
      }
      removePending(response.config);
      return response;
    },
    (error) => {
      if (error.config?.headers?.isLoading !== false) {
        hideLoading();
      }
      error.config && removePending(error.config);
      return Promise.reject(error);
    },
  );

  return service(axiosConfig);
}

export default myAxios;
