import type { HomeStateI } from './state';
import { Dispatch } from 'redux';
import { updateType } from '.';
import { loadData, loadData2 } from './service';
import { add } from './pure';
import { ActionToProps, SomeKeyOfObject } from '../../typings/foundation';
import { GetStateI } from '..';

//  定义actions 各函数入参出参类型
type HomeActionsI = typeof actions;

// 将actions 类型绑定到props上， 成为PROPS interface
export type HomeActionsIProps = ActionToProps<HomeActionsI>;

// 定义actions
export const actions = {
  /**万能更新store */
  updateStore:
    (payload: SomeKeyOfObject<HomeStateI>) => (dispatch: Dispatch) => {
      dispatch({
        type: updateType,
        payload,
      });
    },
  loadData: (type: 1 | 2) => () => {
    // if (type === 1) {
    //   loadData().then(() => {
    //     console.log('load data');
    //   });
    // } else {
    //   loadData2().then(() => {
    //     console.log('load data2');
    //   });
    // }
  },
  asyncAdd: (data) => (dispatch, getState: GetStateI) => {
    async function asyncFn() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    }

    console.log('async~');
    console.log('async请求-state', getState());
    console.log('async请求-请求参数', data);
    asyncFn().then(() => {
      dispatch(actions.updateStore({ count: add(getState().home.count) }));
    });
  },
  getCurGrid: (data) => (dispatch, getState: GetStateI) => {
    return loadData(data).then((res) => {
      return res.data;
    });
  },
};
