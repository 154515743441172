import React from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';

// 请求个数
export let requestCount = 0;

/**
 * Loading
 * */
export function showLoading() {
  if (requestCount === 0) {
    const dom = document.createElement('div');
    dom.setAttribute('id', '__loading__');
    document.body.appendChild(dom);
    ReactDOM.render(<Spin tip="载入中..." size="large" />, dom);
  }
  requestCount++;
}

// 隐藏loading
export function hideLoading() {
  requestCount--;
  if (requestCount === 0) {
    document.body.removeChild(
      document.getElementById('__loading__') as HTMLElement,
    );
  }
}
