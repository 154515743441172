/* eslint-disable @typescript-eslint/no-explicit-any */
import * as H from 'history';
import { RouteProps } from 'react-router-dom';
const config: IRouterConfig[] = [
  {
    path: '/',
    title: '首页',
    exact: true,
    login: true,
    role: [1, 2],
    component: () => import('../pages/home'),
  },
  {
    path: '/search',
    title: '搜索',
    exact: true,
    login: false,
    role: [1, 2],
    component: () => import('../pages/search'),
  },
  {
    path: '/people',
    exact: false,
    component: () => import('../components/router/routerLayout'),
    routes: [
      {
        path: '/people/Tom',
        component: () => import('../pages/people'),
      },
      {
        path: '/people/Bill',
        component: () => import('../pages/people'),
      },
      {
        path: '/people/Alex',
        component: () => import('../pages/people'),
      },
    ],
  },
];

export default config;

interface IRouterHook {
  location: H.Location;
  history: H.History;
}
export interface IRouterConfig {
  path?: string;
  title?: string | (() => void);
  exact?: boolean;
  routes?: IRouterConfig[];
  strict?: boolean;
  component?: () => any | RouteProps['component'];
  hook?: ({
    location,
    history,
  }: IRouterHook) => Promise<boolean> | boolean | void;
  unMount?: () => void;
  render?: () => JSX.Element;
  [key: string]: any;
}
