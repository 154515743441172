import React from 'react';
import { Alert } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import style from './index.module.less';
import config from '../../config';
import { useAction } from '../../store';
import { globalActions } from '../../store/global';

type IType = 'warning' | 'success' | 'info' | 'error';
const { globalAlter, prefix } = config;
const { type, showIcon, closable, message } = globalAlter;
interface IProps {
  showGlobalAlter: boolean;
}

const AppAlter = ({ showGlobalAlter }: IProps) => {
  if (!showGlobalAlter) return null;
  const { updateStore } = useAction(globalActions);

  const closeAlter = () => {
    updateStore({ showGlobalAlter: false });
  };

  return (
    <Alert
      message={
        <div className={style[`${prefix}-alter`]}>
          <p>{message}</p>
        </div>
      }
      type={type as IType}
      action={
        closable ? <CloseOutlined onClick={closeAlter}></CloseOutlined> : null
      }
      showIcon={showIcon}
    />
  );
};

export default AppAlter;
