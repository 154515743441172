import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.less';
// 注意先引入样式文件再引入主组件
// import 'config/antd.less';
import App from './App';
// 临时解决moment等打包问题
window.module = undefined;

function checkIsMobile() {
  const info = navigator.userAgent;
  let agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPod',
    'iPad',
  ];
  for (let i = 0; i < agents.length; i++) {
    if (info.indexOf(agents[i]) >= 0) return true;
  }
  return false;
}

(window as any).isMobile = checkIsMobile();

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
