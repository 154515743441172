import {
  combineReducers,
  applyMiddleware,
  createStore,
  bindActionCreators,
  Dispatch,
  AnyAction,
} from 'redux';
import thunk from 'redux-thunk';
import { ActionToProps, ActionToPropsInput } from '../typings/foundation';
import { GlobalStateI } from './global';
import { HomeStateI } from './home';

export type GetStateI = () => ReduxStoreState;

export type ReduxStoreState = {
  global: GlobalStateI;
  home: HomeStateI;
};

// 自动引入reduces
const reducerModules = import.meta.globEager('./**/index.ts');
const reducers = Object.create(null);
for (const key in reducerModules) {
  const reducerName = key.split('/')[1];
  reducers[reducerName] = reducerModules[key].default;
}

const rootReducers = combineReducers(reducers);

const store = createStore(rootReducers, applyMiddleware(thunk));

export const useAction = <T extends ActionToPropsInput>(
  actions: T,
): ActionToProps<T> => {
  return bindActionCreators(actions, store.dispatch as Dispatch<AnyAction>);
};

export default store;
