/**
 * 判断项目是不是嵌在iframe中
 *
 * @returns Boolean
 * */
const isIframe = () => window.self !== window.top;

/**
 * 从cookie中获取信息
 *
 * @param {name} string
 * @returns string
 * */
const getCookie = (name: string): string => {
  let c_start;
  let c_end;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(`${name}=`);

    if (c_start != -1) {
      c_start = c_start + name.length + 1;
      c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return decodeURIComponent(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
};

/**
 * 判断是不是json
 *
 * @param {str} any
 * @returns boolean
 * */

const isJSON = (str: string): boolean => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  return false;
};

/**
 * 转json对象
 *
 * @param {str} any
 * @returns object
 * */
const Json2Obj = (str: string): { [key: string]: unknown } => {
  if (isJSON(str)) {
    return JSON.parse(str);
  }
  return {};
};

/**
 *  返回一个对象
 *
 * @param {obj} any
 * @returns object
 * */
export const backObject = (obj): { [key: string]: unknown } => {
  const Is = obj != null && !Array.isArray(obj) && typeof obj === 'object';
  return Is ? obj : {};
};

export default {
  getCookie,
  isJSON,
  Json2Obj,
};
