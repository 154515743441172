import utils from '../utils';

enum theme {
  dark = 'dark',
  light = 'light',
}

const config = {
  // 全局类名前缀(注意与config/index.less 中的@prefix保持一致)
  prefix: 'template',
  appId: 3268,
  // 项目名，项目icon
  appName: '项目模板',
  appShortName: 'template',
  appIcon: '',
  // 当前登录用户
  username: utils.Json2Obj(utils.getCookie('userInfo'))?.username,
  // 主题 #TODO
  theme: theme.light,
  // 全局顶部信息提示
  showGlobalAlter: true,
  // 全局顶部信息提示设置 #参考 https://ant.design/components/alert-cn/
  globalAlter: {
    closable: true,
    type: 'warning',
    showIcon: true,
    message: '系统消息:这是一个系统消息', // 默认展示内容，自定义请设置为空，修改src/components/appAlter
  },
};

export default config;

export const mapBoxSk =
  'sk.eyJ1Ijoic2NjLW1hcGJveCIsImEiOiJja3RlNTc1ZzIwNnJuMnVtcGxrYXJ5MmJ3In0.3cIQs5mcYLdj_-uLBpKUxg';

export const mapBoxPk =
  'pk.eyJ1Ijoic2NjLW1hcGJveCIsImEiOiJja292MGsxNXgwMzl0MnZxczJ1ZHJ6MXNhIn0.BP99qksZP77yNqFTyfz_rw';

