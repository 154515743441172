import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import AppAlter from '../appAlter';

import { ReduxStoreState } from 'store';
interface IProps {
  children: React.ReactChild;
}

const Container: React.FC<IProps> = ({ children }: IProps) => {
  const showGlobalAlter = useSelector(
    (state: ReduxStoreState) => state.global.showGlobalAlter,
  );

  return (
    <Fragment>
      <AppAlter showGlobalAlter={!showGlobalAlter} />
      {children}
    </Fragment>
  );
};

export default Container;
