import { backObject } from '../../utils';
import { homeInitState } from './state';
export const updateType = Symbol('STORE_UPDATE');

export default (state = homeInitState, action) => {
  switch (action.type) {
    case updateType:
      const payload = backObject(action.payload);
      return { ...state, ...payload };
    default:
      return state;
  }
};

export { actions as homeActions } from './action';
export type { HomeActionsIProps } from './action';
export type { HomeStateI } from './state';
