import axios from '../../utils/axios';
// 请求抽离
export const loadData = (data) => {
  return axios({
    method: 'POST',
    url: '/api/curGrid',
    data,
  });
};

export const loadData2 = () => {
  return axios({
    url: '/api/crm/user/get_user_basic',
    params: { id: 1 },
  }).catch((e) => {
    console.log(e);
  });
};
