import type { GlobalStateI } from './state';
import { Dispatch } from 'redux';
import { updateType } from '.';
import { backObject } from '../../utils';
import { ActionToProps, SomeKeyOfObject } from '../../typings/foundation';

//  定义actions 各函数入参出参类型
type GlobalActionsI = typeof actions;

// 将actions 类型绑定到props上， 成为PROPS interface
export type GlobalActionsIProps = ActionToProps<GlobalActionsI>;

// 定义actions
export const actions = {
  /**万能更新store */
  updateStore:
    (payload: SomeKeyOfObject<GlobalStateI>) => (dispatch: Dispatch) => {
      dispatch({
        type: updateType,
        payload,
      });
    },
  login: (role) => (dispatch) => {
    dispatch(
      actions.updateStore({
        role: backObject(role).role as number,
      }),
    );
  },
};
